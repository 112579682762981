import { err, JsonDecoder, ok, Result } from "ts.data.json";
import {
  AdminCase,
  AdminCaseWithImagesAndReaders,
  AdminStudyListView,
  Annotation,
  ApiResponse,
  Case,
  CaseAndCountsAdminView,
  CaseAndCountsNonAdminView,
  CaseStatus,
  Config,
  DynamicQueryStatus,
  EllipseAnnotation,
  FixedQueryCategory,
  FixedQueryValue,
  MultilineAnnotation,
  MultilineAnnotationClass,
  MultilineAnnotationClassWithCount,
  FreehandAnnotation,
  FreehandAnnotationClass,
  FreehandAnnotationClassWithCount,
  HPFAnnotation,
  HpfAnnotationClass,
  HpfAnnotationClassWithCount,
  Image,
  ImageAndQuery,
  ImageListView,
  ImageNoCase,
  ImageManagementRecord,
  ImageWithAnnotations,
  ImageWithCase,
  Indication,
  Line,
  LineAnnotation,
  Modality,
  Organization,
  OtherQueryValue,
  Point,
  PointAnnotation,
  PointAnnotationClass,
  PointAnnotationClassWithCount,
  Polygon,
  ProcessedImage,
  ProcessingStatusType,
  QueryAssessmentType,
  QueryObjectType,
  QueryRecord,
  QueryRecordCategory,
  QueryResolutionType,
  QuerySearchRecord,
  QueryStatus,
  QueryType,
  QueryViewRecord,
  ReaderCase,
  ReaderCaseWithImages,
  ReaderStudyListView,
  ReaderStudyStats,
  ResolvedQuery,
  ResolvedQueryType,
  Role,
  SimpleCase,
  Study,
  StudyAccess,
  StudyAdminView,
  StudyListView,
  StudyNoUsers,
  StudyView,
  TextAnnotation,
  UnprocessedImage,
  UnresolvedQuery,
  UnresolvedQueryType,
  UploaderStudyListView,
  User,
  UserInStudy,
  UserSummary,
  ImageManagementCaseRecord,
  StudyAccessRecordView,
  SampleDataSignedURL,
  SampleDataImportResults,
  GetDownloadResponse,
  QueryMetadataRole,
  QueryMetadataReason,
  QueryMetadataReasonValue,
  QueryMetadataReasonDropdownValue,
  QueryMetadata,
  CaseSummary,
  ImageStatusType,
  QueryDetailRecord,
  QueryDetail,
  QueryReminder,
  RoleName,
  QueryStats,
  QuerySearchResultData,
  QueryData
} from "./models";
import { RolePermissions } from "./permissions";

export const dateDecoder = JsonDecoder.string.map(s => new Date(Date.parse(s)));

export function tOrNull<T>(decoder: JsonDecoder.Decoder<T>): JsonDecoder.Decoder<T | null> {
  return JsonDecoder.oneOf<T | null>(
    [decoder, JsonDecoder.isNull(null), JsonDecoder.isUndefined(null)],
    `Optional:${typeof decoder}`
  );
}

export function decodeRolePermissionString(rolePermissionString: string): Result<RolePermissions> {
  //console.log("decoding rolePermissionString=" + rolePermissionString);
  switch (rolePermissionString.trimEnd()) {
    case "IM_ImageListTab_ViewImageslistTab":
      return ok(RolePermissions.IM_ImageListTab_ViewImageslistTab);
    case "IM_ImagesListTab_ViewallImages_GlobalAccess":
      return ok(RolePermissions.IM_ImagesListTab_ViewallImages_GlobalAccess);
    case "IM_ImagesListTab_Viewassignedimagesonly_UnderAssignedStudies":
      return ok(RolePermissions.IM_ImagesListTab_Viewassignedimagesonly_UnderAssignedStudies);
    case "IM_ImagesListTab_AND_ViewImagesoriginatingfromLab":
      return ok(RolePermissions.IM_ImagesListTab_AND_ViewImagesoriginatingfromLab);
    case "IM_ImagesListTab_ExportImageManagementTable":
      return ok(RolePermissions.IM_ImagesListTab_ExportImageManagementTable);
    case "IM_ImagesListTab_DownloadImages":
      return ok(RolePermissions.IM_ImagesListTab_DownloadImages);
    case "IM_ImagesListTab_MoveImagsbetweenStudies":
      return ok(RolePermissions.IM_ImagesListTab_MoveImagsbetweenStudies);
    case "IM_ImagesListTab_MoveImagebetweenCases":
      return ok(RolePermissions.IM_ImagesListTab_MoveImagebetweenCases);
    case "IM_ImagesListTab_Search":
      return ok(RolePermissions.IM_ImagesListTab_Search);
    case "IM_ImagesListTab_Archive":
      return ok(RolePermissions.IM_ImagesListTab_Archive);
    case "IM_ImagesListTab_CopyImage":
      return ok(RolePermissions.IM_ImagesListTab_CopyImage);
    case "IM_ImagesListTab_UploadImagestoAssignedStudiesOnly":
      return ok(RolePermissions.IM_ImagesListTab_UploadImagestoAssignedStudiesOnly);
    case "IM_ImagesListTab_AssignImagetoCase":
      return ok(RolePermissions.IM_ImagesListTab_AssignImagetoCase);
    case "IM_CaseListTab_ViewPage":
      return ok(RolePermissions.IM_CaseListTab_ViewPage);
    case "IM_CaseListTab_ViewallCases":
      return ok(RolePermissions.IM_CaseListTab_ViewallCases);
    case "IM_CaseListTab_ViewAssignedCasesonly":
      return ok(RolePermissions.IM_CaseListTab_ViewAssignedCasesonly);
    case "IM_CaseListTab_Search":
      return ok(RolePermissions.IM_CaseListTab_Search);
    case "IM_CaseListTab_EditCase":
      return ok(RolePermissions.IM_CaseListTab_EditCase);
    case "IM_CaseListTab_ArchiveCase":
      return ok(RolePermissions.IM_CaseListTab_ArchiveCase);
    case "IM_CaseListTab_ChangeStatus":
      return ok(RolePermissions.IM_CaseListTab_ChangeStatus);
    case "IM_CaseListTab_PutoHold":
      return ok(RolePermissions.IM_CaseListTab_PutoHold);
    case "IM_CaseListTab_ExportCaseLevelReport":
      return ok(RolePermissions.IM_CaseListTab_ExportCaseLevelReport);
    case "IM_CaseListTab_DownloadImages":
      return ok(RolePermissions.IM_CaseListTab_DownloadImages);
    case "S_Main_ViewStudyPage":
      return ok(RolePermissions.S_Main_ViewStudyPage);
    case "S_Main_ViewAllStudies":
      return ok(RolePermissions.S_Main_ViewAllStudies);
    case "S_Main_ViewAssignedStudiesOnly":
      return ok(RolePermissions.S_Main_ViewAssignedStudiesOnly);
    case "S_Main_CreateStudy":
      return ok(RolePermissions.S_Main_CreateStudy);
    case "S_Main_EditStudy":
      return ok(RolePermissions.S_Main_EditStudy);
    case "S_CaseListTab_CreateCase":
      return ok(RolePermissions.S_CaseListTab_CreateCase);
    case "S_CaseListTab_EditCase":
      return ok(RolePermissions.S_CaseListTab_EditCase);
    case "S_CaseListTab_ViewallCasesUnderAssignedStudies":
      return ok(RolePermissions.S_CaseListTab_ViewallCasesUnderAssignedStudies);
    case "S_CaseListTab_ViewAssignedCasesonly":
      return ok(RolePermissions.S_CaseListTab_ViewAssignedCasesonly);
    case "S_ImageListTab_ViewImageTab":
      return ok(RolePermissions.S_ImageListTab_ViewImageTab);
    case "S_ImageListTab_UploadImage":
      return ok(RolePermissions.S_ImageListTab_UploadImage);
    case "S_ImageListTab_MoveImage":
      return ok(RolePermissions.S_ImageListTab_MoveImage);
    case "S_ImageListTab_CopyImage":
      return ok(RolePermissions.S_ImageListTab_CopyImage);
    case "AP_ImageViewer_ViewimageViewer":
      return ok(RolePermissions.AP_ImageViewer_ViewimageViewer);
    case "AP_ImageViewer_ViewLabel":
      return ok(RolePermissions.AP_ImageViewer_ViewLabel);
    case "AP_ImageViewer_ViewAnnotationsToolbar":
      return ok(RolePermissions.AP_ImageViewer_ViewAnnotationsToolbar);
    case "AP_ImageViewer_ViewAnnotations":
      return ok(RolePermissions.AP_ImageViewer_ViewAnnotations);
    case "AP_ImageViewer_EditCreateAnnotations":
      return ok(RolePermissions.AP_ImageViewer_EditCreateAnnotations);
    case "AP_ImageViewer_ViewButtonPassLabQC":
      return ok(RolePermissions.AP_ImageViewer_ViewButtonPassLabQC);
    case "AP_ImageViewer_Addcommenttoimage":
      return ok(RolePermissions.AP_ImageViewer_Addcommenttoimage);
    case "AP_CaseViewer_Viewcaseview":
      return ok(RolePermissions.AP_CaseViewer_Viewcaseview);
    case "AP_CaseViewer_ViewSidebar":
      return ok(RolePermissions.AP_CaseViewer_ViewSidebar);
    case "AP_CaseViewer_ViewQueries":
      return ok(RolePermissions.AP_CaseViewer_ViewQueries);
    case "AP_CaseViewer_ViewAuditTrail":
      return ok(RolePermissions.AP_CaseViewer_ViewAuditTrail);
    case "AP_CaseViewer_ViewButtonPassISCQC":
      return ok(RolePermissions.AP_CaseViewer_ViewButtonPassISCQC);
    case "AP_CaseViewer_MarkCaseasScored":
      return ok(RolePermissions.AP_CaseViewer_MarkCaseasScored);
    case "AP_CaseViewer_ManualCRassignment":
      return ok(RolePermissions.AP_CaseViewer_ManualCRassignment);
    case "AP_ImageData_ViewFilename":
      return ok(RolePermissions.AP_ImageData_ViewFilename);
    case "AP_ImageData_EditFileName":
      return ok(RolePermissions.AP_ImageData_EditFileName);
    case "AP_ImageData_ViewAnatomicalSegment":
      return ok(RolePermissions.AP_ImageData_ViewAnatomicalSegment);
    case "AP_ImageData_EditAnatomicalSegment":
      return ok(RolePermissions.AP_ImageData_EditAnatomicalSegment);
    case "AP_ImageData_ViewImageStatus":
      return ok(RolePermissions.AP_ImageData_ViewImageStatus);
    case "AP_ImageData_EditImageStatus":
      return ok(RolePermissions.AP_ImageData_EditImageStatus);
    case "AP_ImageData_ViewInQuery":
      return ok(RolePermissions.AP_ImageData_ViewInQuery);
    case "AP_ImageData_EditInQuery":
      return ok(RolePermissions.AP_ImageData_EditInQuery);
    case "AP_ImageData_ViewProtocolID":
      return ok(RolePermissions.AP_ImageData_ViewProtocolID);
    case "AP_ImageData_EditProtocolID":
      return ok(RolePermissions.AP_ImageData_EditProtocolID);
    case "AP_ImageData_ViewImageUploadDate":
      return ok(RolePermissions.AP_ImageData_ViewImageUploadDate);
    case "AP_ImageData_EditImageUploadDate":
      return ok(RolePermissions.AP_ImageData_EditImageUploadDate);
    case "AP_ImageData_ViewQAComments":
      return ok(RolePermissions.AP_ImageData_ViewQAComments);
    case "AP_ImageData_EditQAComments":
      return ok(RolePermissions.AP_ImageData_EditQAComments);
    case "AP_ImageData_ViewAccesionNumber":
      return ok(RolePermissions.AP_ImageData_ViewAccesionNumber);
    case "AP_ImageData_EditAccessionNumber":
      return ok(RolePermissions.AP_ImageData_EditAccessionNumber);
    case "AP_ImageData_ViewUploaderOrganization":
      return ok(RolePermissions.AP_ImageData_ViewUploaderOrganization);
    case "AP_ImageData_EditUploaderOrganization":
      return ok(RolePermissions.AP_ImageData_EditUploaderOrganization);
    case "AP_CaseData_ViewSiteID":
      return ok(RolePermissions.AP_CaseData_ViewSiteID);
    case "AP_CaseData_EditSiteID":
      return ok(RolePermissions.AP_CaseData_EditSiteID);
    case "AP_CaseData_ViewVisitID":
      return ok(RolePermissions.AP_CaseData_ViewVisitID);
    case "AP_CaseData_EditVisitID":
      return ok(RolePermissions.AP_CaseData_EditVisitID);
    case "AP_CaseData_ViewSubjectID":
      return ok(RolePermissions.AP_CaseData_ViewSubjectID);
    case "AP_CaseData_EditSubjectID":
      return ok(RolePermissions.AP_CaseData_EditSubjectID);
    case "AP_CaseData_ViewCollectionDate":
      return ok(RolePermissions.AP_CaseData_ViewCollectionDate);
    case "AP_CaseData_EditCollectionDate":
      return ok(RolePermissions.AP_CaseData_EditCollectionDate);
    case "AP_CaseData_ViewExpectedNoofimages":
      return ok(RolePermissions.AP_CaseData_ViewExpectedNoofimages);
    case "AP_CaseData_EditExpectedNoofImages":
      return ok(RolePermissions.AP_CaseData_EditExpectedNoofImages);
    case "AP_CaseData_ViewNoofimagesReceived":
      return ok(RolePermissions.AP_CaseData_ViewNoofimagesReceived);
    case "AP_CaseData_EditNoofImagesReceived":
      return ok(RolePermissions.AP_CaseData_EditNoofImagesReceived);
    case "AP_CaseData_ViewEndoProcedureID":
      return ok(RolePermissions.AP_CaseData_ViewEndoProcedureID);
    case "AP_CaseData_EditEndoProcedureID":
      return ok(RolePermissions.AP_CaseData_EditEndoProcedureID);
    case "AP_CaseData_ViewHistopathologyProcedureID":
      return ok(RolePermissions.AP_CaseData_ViewHistopathologyProcedureID);
    case "AP_CaseData_EditHistopathologyProcedureID":
      return ok(RolePermissions.AP_CaseData_EditHistopathologyProcedureID);
    case "AP_StudyData_ViewStudyName":
      return ok(RolePermissions.AP_StudyData_ViewStudyName);
    case "AP_StudyData_EditStudyName":
      return ok(RolePermissions.AP_StudyData_EditStudyName);
    case "AP_StudyData_EditStudyNamePopulated":
      return ok(RolePermissions.AP_StudyData_EditStudyNamePopulated);
    case "AP_StudyData_ViewIndication":
      return ok(RolePermissions.AP_StudyData_ViewIndication);
    case "AP_StudyData_EditIndication":
      return ok(RolePermissions.AP_StudyData_EditIndication);
    case "AP_StudyData_ViewIndicationPopulated":
      return ok(RolePermissions.AP_StudyData_ViewIndicationPopulated);
    case "AP_StudyData_ViewSponsor":
      return ok(RolePermissions.AP_StudyData_ViewSponsor);
    case "AP_StudyData_EditSponsor":
      return ok(RolePermissions.AP_StudyData_EditSponsor);
    case "AP_StudyData_EditSponsorPopulated":
      return ok(RolePermissions.AP_StudyData_EditSponsorPopulated);
    case "Q_ViewQueriesImages":
      return ok(RolePermissions.Q_ViewQueriesImages);
    case "Q_ViewQueriesCases":
      return ok(RolePermissions.Q_ViewQueriesCases);
    case "Q_ViewAllQueries":
      return ok(RolePermissions.Q_ViewAllQueries);
    case "Q_ViewQueriesAssignedtoUserRoleQueriesOnly":
      return ok(RolePermissions.Q_ViewQueriesAssignedtoUserRoleQueriesOnly);
    case "Q_ViewallQueriesunderAssignedStudies":
      return ok(RolePermissions.Q_ViewallQueriesunderAssignedStudies);
    case "Q_IssueCaseQuery":
      return ok(RolePermissions.Q_IssueCaseQuery);
    case "Q_IssueImageQueryStudy":
      return ok(RolePermissions.Q_IssueImageQueryStudy);
    case "Q_IssueImageQueryOrphanedImage":
      return ok(RolePermissions.Q_IssueImageQueryOrphanedImage);
    case "Q_Commentonassignedqueriesonly":
      return ok(RolePermissions.Q_Commentonassignedqueriesonly);
    case "Q_MarkQueryasResolved":
      return ok(RolePermissions.Q_MarkQueryasResolved);
    case "Q_Close_CancelQueryunderAssignedStudies":
      return ok(RolePermissions.Q_Close_CancelQueryunderAssignedStudies);
    case "Q_Close_CancelQueryunderUserRoleOnly":
      return ok(RolePermissions.Q_Close_CancelQueryunderUserRoleOnly);
    case "Q_Flagforfollowup":
      return ok(RolePermissions.Q_Flagforfollowup);
    case "Q_ReplaceImage":
      return ok(RolePermissions.Q_ReplaceImage);
    case "UM_ViewUsersPage":
      return ok(RolePermissions.UM_ViewUsersPage);
    case "UM_RegisterUser":
      return ok(RolePermissions.UM_RegisterUser);
    case "UM_GrantStudyAccess":
      return ok(RolePermissions.UM_GrantStudyAccess);
    case "UM_RevokeStudyAccess":
      return ok(RolePermissions.UM_RevokeStudyAccess);
    case "R_ViewReportsTab":
      return ok(RolePermissions.R_ViewReportsTab);
    case "R_GenerateAuditTrailPDFonly":
      return ok(RolePermissions.R_GenerateAuditTrailPDFonly);
    case "R_GenerateAuditTrailPDForCSV":
      return ok(RolePermissions.R_GenerateAuditTrailPDForCSV);
    case "R_GenerateIDR":
      return ok(RolePermissions.R_GenerateIDR);
    case "R_GenerateHPFAnnotationsReport":
      return ok(RolePermissions.R_GenerateHPFAnnotationsReport);
    case "R_GenerateQueryLevelAccessReport":
      return ok(RolePermissions.R_GenerateQueryLevelAccessReport);
    case "R_GenerateStudyLevelAccessReport":
      return ok(RolePermissions.R_GenerateStudyLevelAccessReport);
    case "QP_SystemAdminAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_SystemAdminAssignedtostudytheImageCaseQuery);
    case "QP_StudyAdminAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_StudyAdminAssignedtostudytheImageCaseQuery);
    case "QP_ISCAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_ISCAssignedtostudytheImageCaseQuery);
    case "QP_DMAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_DMAssignedtostudytheImageCaseQuery);
    case "QP_PMAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_PMAssignedtostudytheImageCaseQuery);
    case "QP_SponsorAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_SponsorAssignedtostudytheImageCaseQuery);
    case "QP_SiteAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_SiteAssignedtostudytheImageCaseQuery);
    case "QP_UploaderAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_UploaderAssignedtostudytheImageCaseQuery);
    case "QP_CentralReaderAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_CentralReaderAssignedtostudytheImageCaseQuery);
    case "QP_LabTechAssignedtostudytheImageCaseQuery":
      return ok(RolePermissions.QP_LabTechAssignedtostudytheImageCaseQuery);

    default:
      return err<RolePermissions>(`Cannot decode role permission from ${rolePermissionString}`);
  }
}

export const rolePermissionDecoder = new JsonDecoder.Decoder<RolePermissions>((json: any) => {
  return typeof json === "string"
    ? decodeRolePermissionString(json)
    : err<RolePermissions>(`Cannot decode role permssion ${json}`);
});

export const rolePermissionsDecoder = JsonDecoder.array(rolePermissionDecoder, "rolePermissions");
export const nullableRolePermissionsDecoder = tOrNull(rolePermissionsDecoder);

export const optionalStringDecoder = tOrNull(JsonDecoder.string);
export const optionalNumberDecoder = tOrNull(JsonDecoder.number);

export function decodeRoleNameString(roleNameString: string): Result<RoleName> {
  switch (roleNameString.toUpperCase()) {
    case "STUDYADMIN":
      return ok(RoleName.StudyAdmin);
    case "SPONSOR":
      return ok(RoleName.Sponsor);
    case "ISC":
      return ok(RoleName.ISC);
    case "SYSTEMADMIN":
      return ok(RoleName.SystemAdmin);
    case "UPLOADER":
      return ok(RoleName.Uploader);
    case "LABTECH":
      return ok(RoleName.LabTech);
    case "CR":
      return ok(RoleName.CR);
    case "SITE":
      return ok(RoleName.Site);
    case "PM":
      return ok(RoleName.PM);
    case "DM":
      return ok(RoleName.DM);
    case "NOTO":
      return ok(RoleName.NOTO);
    case "LIS":
      return ok(RoleName.LIS);
    default:
      return err<RoleName>(`Cannot decode user role from ${roleNameString}`);
  }
}

export const roleNameDecoder = new JsonDecoder.Decoder<RoleName>((json: any) => {
  return typeof json === "string"
    ? decodeRoleNameString(json)
    : err<RoleName>(`Cannot decode user role from ${json}`);
});

export const roleDecoder = JsonDecoder.object<Role>(
  {
    id: JsonDecoder.string,
    name: roleNameDecoder,
    displayName: JsonDecoder.string,
    description: JsonDecoder.string,
    roleType: JsonDecoder.string,
    createdAt: dateDecoder,
    updatedAt: dateDecoder
  },
  "Role"
);

export const organizationDecoder = JsonDecoder.object<Organization>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    groupEmail: tOrNull(JsonDecoder.string)
  },
  "Organization"
);

export const studyAccessDecoder = JsonDecoder.object<StudyAccess>(
  {
    studyId: JsonDecoder.string,
    studyName: JsonDecoder.string,
    roleId: JsonDecoder.string,
    roleName: JsonDecoder.string
  },
  "StudyAccess"
);

export const userDecoder = JsonDecoder.object<User>(
  {
    id: JsonDecoder.string,
    username: JsonDecoder.string,
    lastLoginDt: tOrNull(dateDecoder),
    firstName: optionalStringDecoder,
    lastName: optionalStringDecoder,
    organizationId: optionalStringDecoder,
    registered: JsonDecoder.boolean,
    workAsRole: tOrNull(roleDecoder),
    actions: nullableRolePermissionsDecoder,
    isSystemAdmin: tOrNull(JsonDecoder.boolean)
  },
  "User"
);

export const userSummaryDecoder = JsonDecoder.object<UserSummary>(
  {
    id: JsonDecoder.string,
    username: JsonDecoder.string,
    lastLoginDt: tOrNull(dateDecoder),
    firstName: optionalStringDecoder,
    lastName: optionalStringDecoder,
    registered: JsonDecoder.boolean,
    organizationName: tOrNull(JsonDecoder.string),
    studyCount: JsonDecoder.number
  },
  "UserSummary"
);

export const userInStudyDecoder = JsonDecoder.object<UserInStudy>(
  {
    id: JsonDecoder.string,
    username: JsonDecoder.string,
    lastLoginDt: tOrNull(dateDecoder),
    firstName: optionalStringDecoder,
    lastName: optionalStringDecoder,
    organizationId: optionalStringDecoder,
    registered: JsonDecoder.boolean,
    roleId: tOrNull(JsonDecoder.string),
    roleName: JsonDecoder.string,
    organizationName: tOrNull(JsonDecoder.string)
  },
  "UserInStudy"
);

export const readerStudyStats = JsonDecoder.object<ReaderStudyStats>(
  {
    reader: userDecoder,
    numReadCases: JsonDecoder.number,
    numTotalCases: JsonDecoder.number
  },
  "ReaderStudyStats"
);

export const readersStudyStatsDecoder = JsonDecoder.array(readerStudyStats, "ReadersStudyStats");

export const rolesDecoder = JsonDecoder.array(roleDecoder, "Roles");

export const organizationsDecoder = JsonDecoder.array(organizationDecoder, "Organizations");
export const studiesAccessDecoder = JsonDecoder.array(studyAccessDecoder, "StudiesAccess");

export const usersDecoder = JsonDecoder.array(userDecoder, "Users");

export const userSummariesDecoder = JsonDecoder.array(userSummaryDecoder, "UserSummaries");
export const usersInStudyDecoder = JsonDecoder.array(userInStudyDecoder, "UsersInStudy");

export function decodeIndicationString(indicationString: string): Result<Indication> {
  switch (indicationString.toUpperCase()) {
    case "CROHNS_DISEASE":
      return ok(Indication.CrohnsDisease);
    case "ULCERATIVE_COLITIS":
      return ok(Indication.UlcerativeColitis);
    case "EOSINOPHILIC_ESOPHAGITIS":
      return ok(Indication.EosinophilicEsophagitis);
    case "NONALCOHOLIC_STEATOHEPATITIS":
      return ok(Indication.NonalcoholicSteatohepatitis);
    case "POUCHITIS":
      return ok(Indication.Pouchitis);
    case "CELIAC_DISEASE":
      return ok(Indication.CeliacDisease);
    case "PRIMARY_SCLEROSING_CHOLANGITIS":
      return ok(Indication.PrimarySclerosingCholangitis);
    case "EOSINOPHILIC_DUODENITIS":
      return ok(Indication.EosinophilicDuodenitis);
    case "EOSINOPHILIC_GASTROENTERITIS":
      return ok(Indication.EosinophilicGastroenteritis);
    default:
      return err<Indication>(`Cannot decode indication from ${indicationString}`);
  }
}

export const indicationDecoder = new JsonDecoder.Decoder<Indication>((json: any) => {
  return typeof json === "string"
    ? decodeIndicationString(json)
    : err<Indication>(`Cannot decode indication from ${json}`);
});

export function decodeModalityString(modalityString: string): Result<Modality> {
  switch (modalityString.toUpperCase()) {
    case "HISTOPATHOLOGY":
      return ok(Modality.Histopathology);
    case "ENDOSCOPY":
      return ok(Modality.Endoscopy);
    case "IMMUNOHISTOCHEMISTRY":
      return ok(Modality.Immunohistochemistry);
    default:
      return err<Modality>(`Cannot decode modality from ${modalityString}`);
  }
}

export const modalityDecoder = new JsonDecoder.Decoder<Modality>((json: any) => {
  return typeof json === "string"
    ? decodeModalityString(json)
    : err<Modality>(`Cannot decode modality from ${json}`);
});

export const studyDecoder = JsonDecoder.object<Study>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    sponsor: tOrNull(JsonDecoder.string),
    protocolIds: JsonDecoder.array<string>(JsonDecoder.string, "protocolIds"),
    visitIds: JsonDecoder.array<string>(JsonDecoder.string, "visitIds"),
    indications: JsonDecoder.array<Indication>(indicationDecoder, "indications"),
    segments: JsonDecoder.number,
    anatomicalSegments: JsonDecoder.array<string>(JsonDecoder.string, "anatomicalSegments"),
    modality: JsonDecoder.array<Modality>(modalityDecoder, "modality"),
    onHold: JsonDecoder.boolean,
    onHoldReason: tOrNull(JsonDecoder.string),
    createdAt: dateDecoder
  },
  "Study"
);

export const studiesDecoder = JsonDecoder.array(studyDecoder, "Studies");

export const studyNoUsersDecoder = JsonDecoder.object<StudyNoUsers>(
  {
    study: studyDecoder,
    isNoto: tOrNull(JsonDecoder.boolean)
  },
  "StudyNoUsers"
);

export const multilineAnnotationClassDecoder = JsonDecoder.object<MultilineAnnotationClass>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    color: JsonDecoder.string,
    sortOrder: tOrNull(JsonDecoder.number),
    type: JsonDecoder.isExactly("MULTILINE"),
    enabled: JsonDecoder.boolean
  },
  "MultilineAnnotationClass"
);

export const multilineAnnotationClassArrayDecoder = JsonDecoder.array<MultilineAnnotationClass>(
  multilineAnnotationClassDecoder,
  "MultilineAnnotationClasses"
);

export const multilineAnnotationClassWithCountDecoder = JsonDecoder.object<MultilineAnnotationClassWithCount>(
  {
    annotationClass: multilineAnnotationClassDecoder,
    count: JsonDecoder.number
  },
  "MultilineAnnotationClassWithCount"
);

export const multilineAnnotationClassWithCountArrayDecoder = JsonDecoder.array<MultilineAnnotationClassWithCount>(
  multilineAnnotationClassWithCountDecoder,
  "MultilineAnnotationClassesWithCount"
);

export const freehandAnnotationClassDecoder = JsonDecoder.object<FreehandAnnotationClass>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    color: JsonDecoder.string,
    sortOrder: tOrNull(JsonDecoder.number),
    type: JsonDecoder.isExactly("FREEHAND"),
    enabled: JsonDecoder.boolean
  },
  "FreehandAnnotationClass"
);

export const freehandAnnotationClassArrayDecoder = JsonDecoder.array<FreehandAnnotationClass>(
  freehandAnnotationClassDecoder,
  "FreehandAnnotationClasses"
);

export const freehandAnnotationClassWithCountDecoder = JsonDecoder.object<FreehandAnnotationClassWithCount>(
  {
    annotationClass: freehandAnnotationClassDecoder,
    count: JsonDecoder.number
  },
  "FreehandAnnotationClassWithCount"
);

export const freehandAnnotationClassWithCountArrayDecoder = JsonDecoder.array<FreehandAnnotationClassWithCount>(
  freehandAnnotationClassWithCountDecoder,
  "FreehandAnnotationClassesWithCount"
);

export const pointAnnotationClassDecoder = JsonDecoder.object<PointAnnotationClass>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    color: JsonDecoder.string,
    sortOrder: tOrNull(JsonDecoder.number),
    type: JsonDecoder.isExactly("POINT"),
    enabled: JsonDecoder.boolean
  },
  "PointAnnotationClass"
);

export const pointAnnotationClassArrayDecoder = JsonDecoder.array<PointAnnotationClass>(
  pointAnnotationClassDecoder,
  "PointAnnotationClasses"
);

export const pointAnnotationClassWithCountDecoder = JsonDecoder.object<PointAnnotationClassWithCount>(
  {
    annotationClass: pointAnnotationClassDecoder,
    count: JsonDecoder.number
  },
  "PointAnnotationClassWithCount"
);

export const pointAnnotationClassWithCountArrayDecoder = JsonDecoder.array<PointAnnotationClassWithCount>(
  pointAnnotationClassWithCountDecoder,
  "PointAnnotationClassesWithCount"
);

export const hpfAnnotationClassDecoder = JsonDecoder.object<HpfAnnotationClass>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    color: JsonDecoder.string,
    sortOrder: tOrNull(JsonDecoder.number),
    type: JsonDecoder.isExactly("HPF"),
    enabled: JsonDecoder.boolean
  },
  "HpfAnnotationClass"
);

export const hpfAnnotationClassArrayDecoder = JsonDecoder.array<HpfAnnotationClass>(
  hpfAnnotationClassDecoder,
  "HpfAnnotationClasses"
);

export const hpfAnnotationClassWithCountDecoder = JsonDecoder.object<HpfAnnotationClassWithCount>(
  {
    annotationClass: hpfAnnotationClassDecoder,
    count: JsonDecoder.number
  },
  "HpfAnnotationClassWithCount"
);

export const hpfAnnotationClassWithCountArrayDecoder = JsonDecoder.array<HpfAnnotationClassWithCount>(
  hpfAnnotationClassWithCountDecoder,
  "HpfAnnotationClassesWithCount"
);

export const studyAdminViewDecoder = JsonDecoder.object<StudyAdminView>(
  {
    study: studyDecoder,
    uploaders: usersInStudyDecoder, //JsonDecoder.array<UsersInStudy>(usersInStudyDecoder, "uploaders"),
    readers: usersInStudyDecoder, //JsonDecoder.array<UsersInStudy>(usersInStudyDecoder, "readers"),
    hpfAnnotationClasses: JsonDecoder.array<HpfAnnotationClassWithCount>(
      hpfAnnotationClassWithCountDecoder,
      "hpfAnnotationClasses"
    ),
    pointAnnotationClasses: JsonDecoder.array<PointAnnotationClassWithCount>(
      pointAnnotationClassWithCountDecoder,
      "pointAnnotationClasses"
    ),
    freehandAnnotationClasses: JsonDecoder.array<FreehandAnnotationClassWithCount>(
      freehandAnnotationClassWithCountDecoder,
      "freehandAnnotationClasses"
    ),
    multilineAnnotationClasses: JsonDecoder.array<MultilineAnnotationClassWithCount>(
      multilineAnnotationClassWithCountDecoder,
      "multilineAnnotationClasses"
    ),
    isNoto: tOrNull(JsonDecoder.boolean)
  },
  "StudyWithUsers"
);

export const studyViewDecoder = JsonDecoder.oneOf<StudyView>(
  [studyAdminViewDecoder, studyNoUsersDecoder],
  "StudyView"
);

export const readerStudyListView = JsonDecoder.object<ReaderStudyListView>(
  {
    studyView: studyNoUsersDecoder,
    numberOfAssignedReadCases: JsonDecoder.number,
    numberOfAssignedUnreadCases: JsonDecoder.number
  },
  "ReaderStudyListView"
);

export const uploaderStudyListView = JsonDecoder.object<UploaderStudyListView>(
  {
    studyView: studyNoUsersDecoder,
    numberOfQueriesOpen: JsonDecoder.number
  },
  "UploaderStudyListView"
);

export const getDownloadResponseDecoder = JsonDecoder.object<GetDownloadResponse>(
  {
    url: JsonDecoder.string
  },
  "GetDownloadResponse"
);

export const sampleDataSignedURLDecoder = JsonDecoder.object<SampleDataSignedURL>(
  {
    s3SampleDataKey: JsonDecoder.string,
    fileName: JsonDecoder.string,
    resultFileKey: JsonDecoder.string
  },
  "SampleDataSignedURL"
);

export const sampleDataImportResultsDecoder = JsonDecoder.object<SampleDataImportResults>(
  {
    message: JsonDecoder.string,
    numRowsUpdated: JsonDecoder.number,
    numFieldsUpdated: JsonDecoder.number,
    numRowsFailed: JsonDecoder.number,
    s3SampleDataResponse: JsonDecoder.string
  },
  "SampleDataImportResults"
);

export const adminStudyListView = JsonDecoder.object<AdminStudyListView>(
  {
    studyView: studyAdminViewDecoder,
    mostRecentUploadAt: tOrNull(dateDecoder),
    numberOfCasesNeedingQC: JsonDecoder.number,
    numberOfQueriesAnswered: JsonDecoder.number,
    numberOfQueriesOpen: JsonDecoder.number,
    numberOfUnassignedImages: JsonDecoder.number
  },
  "AdminStudyListView"
);

export const studyListViewDecoder = JsonDecoder.oneOf<
  ReaderStudyListView | AdminStudyListView | UploaderStudyListView
>([readerStudyListView, adminStudyListView, uploaderStudyListView], "StudyListView");

export const studyListViewsDecoder = JsonDecoder.array<StudyListView>(
  studyListViewDecoder,
  "StudyListViews"
);

export const configDecoder = JsonDecoder.object<Config>(
  {
    ssoLoginUrl: JsonDecoder.string,
    ssoLogoutUrl: JsonDecoder.string,
    tileServerLocation: JsonDecoder.string
  },
  "Config"
);

const latLngDecoder = JsonDecoder.tuple(
  [JsonDecoder.number, JsonDecoder.number],
  "[number, number]"
);

const lineDecoder = JsonDecoder.object<Line>(
  {
    type: JsonDecoder.isExactly("LineString"),
    coordinates: JsonDecoder.array(latLngDecoder, "Points")
  },
  "Line"
);

const polygonDecoder = JsonDecoder.object<Polygon>(
  {
    type: JsonDecoder.isExactly("Polygon"),
    coordinates: JsonDecoder.array(JsonDecoder.array(latLngDecoder, "Points"), "Coords")
  },
  "Polygon"
);

const pointDecoder = JsonDecoder.object<Point>(
  {
    type: JsonDecoder.isExactly("Point"),
    coordinates: latLngDecoder,
    parent: optionalStringDecoder
  },
  "Point"
);

export const ellipseAnnotationDecoder = JsonDecoder.object<EllipseAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    radiusX: JsonDecoder.number,
    radiusY: JsonDecoder.number,
    tilt: JsonDecoder.number,
    text: optionalStringDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string
  },
  "EllipseAnnotation"
);

export const textAnnotationDecoder = JsonDecoder.object<TextAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    text: JsonDecoder.string,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string
  },
  "TextAnnotation"
);

export const hpfAnnotationDecoder = JsonDecoder.object<HPFAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    radius: JsonDecoder.number,
    weight: optionalNumberDecoder,
    text: optionalStringDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string,
    color: tOrNull(JsonDecoder.string)
  },
  "HPFAnnotation"
);

export const pointAnnotationDecoder = JsonDecoder.object<PointAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: pointDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string,
    parent: tOrNull(JsonDecoder.string),
    color: tOrNull(JsonDecoder.string),
    weight: optionalNumberDecoder
  },
  "PointAnnotation"
);

export const lineAnnotationDecoder = JsonDecoder.object<LineAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: lineDecoder,
    length: tOrNull(JsonDecoder.number),
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string
  },
  "LineAnnotation"
);

export const multilineAnnotationDecoder = JsonDecoder.object<MultilineAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: lineDecoder,
    length: tOrNull(JsonDecoder.number),
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string,
    color: tOrNull(JsonDecoder.string),
    annotationClassId: JsonDecoder.string,
    annotationClassName: tOrNull(JsonDecoder.string),
    multilineCategory: JsonDecoder.string
  },
  "MultiLineAnnotation"
);

export const freehandAnnotationDecoder = JsonDecoder.object<FreehandAnnotation>(
  {
    id: JsonDecoder.string,
    geometry: polygonDecoder,
    createdAt: dateDecoder,
    annotationType: JsonDecoder.string,
    userId: JsonDecoder.string,
    imageId: JsonDecoder.string,
    color: tOrNull(JsonDecoder.string)
  },
  "FreehandAnnotation"
);

export const annotationDecoder = JsonDecoder.oneOf<
  | TextAnnotation
  | EllipseAnnotation
  | HPFAnnotation
  | PointAnnotation
  | MultilineAnnotation
  | LineAnnotation
  | FreehandAnnotation
>(
  [
    ellipseAnnotationDecoder,
    textAnnotationDecoder,
    hpfAnnotationDecoder,
    pointAnnotationDecoder,
    multilineAnnotationDecoder,
    lineAnnotationDecoder,
    freehandAnnotationDecoder
  ],
  "AnnotationDecoder"
);

export const annotationsDecoder = JsonDecoder.array(annotationDecoder, "Annotation");

export function decodeQueryStatus(queryStatusString: string): Result<QueryStatus> {
  switch (queryStatusString.toUpperCase()) {
    case "OPEN":
      return ok(QueryStatus.Open);
    case "CLOSED":
      return ok(QueryStatus.Closed);
    default:
      return err<QueryStatus>(`Cannot decode query status type from ${queryStatusString}`);
  }
}

export const decodeQueryStatusDecoder = new JsonDecoder.Decoder<QueryStatus>((json: any) => {
  return typeof json === "string"
    ? decodeQueryStatus(json)
    : err<QueryStatus>(`Cannot decode query status type from ${json}`);
});

export function decodeDynamicQueryStatus(
  dynamicQueryStatusString: string
): Result<DynamicQueryStatus> {
  switch (dynamicQueryStatusString.toUpperCase()) {
    case "ANSWERED":
      return ok(DynamicQueryStatus.Answered);
    case "FOLLOWUPREQUIRED":
      return ok(DynamicQueryStatus.FollowUpRequired);
    case "FOLLOWEDUP":
      return ok(DynamicQueryStatus.FollowedUp);
    case "UPCOMINGDUEDATE":
      return ok(DynamicQueryStatus.UpcomingDueDate);
    default:
      return err<DynamicQueryStatus>(
        `Cannot decode query assessment type from ${dynamicQueryStatusString}`
      );
  }
}

export const decodeDynamicQueryStatusDecoder = new JsonDecoder.Decoder<DynamicQueryStatus>(
  (json: any) => {
    return typeof json === "string"
      ? decodeDynamicQueryStatus(json)
      : err<DynamicQueryStatus>(`Cannot decode dynamic query status type from ${json}`);
  }
);

export function decodeQueryAssessmentType(
  assessmentTypeString: string
): Result<QueryAssessmentType> {
  switch (assessmentTypeString.toUpperCase()) {
    case "PRE":
      return ok(QueryAssessmentType.Pre);
    case "POST":
      return ok(QueryAssessmentType.Post);
    default:
      return err<QueryAssessmentType>(
        `Cannot decode query assessment type from ${assessmentTypeString}`
      );
  }
}

export const queryAssessmentTypeDecoder = new JsonDecoder.Decoder<QueryAssessmentType>(
  (json: any) => {
    return typeof json === "string"
      ? decodeQueryAssessmentType(json)
      : err<QueryAssessmentType>(`Cannot decode query assessment type from ${json}`);
  }
);

export function decodeQueryResolutionString(resolutionString: string): Result<QueryResolutionType> {
  switch (resolutionString.toUpperCase()) {
    case "RESOLVED":
      return ok(QueryResolutionType.Resolved);
    case "UNRESOLVABLE":
      return ok(QueryResolutionType.Unresolvable);
    default:
      return err<QueryResolutionType>(`Cannot decode query resolution from ${resolutionString}`);
  }
}

export const queryResolutionDecoder = new JsonDecoder.Decoder<QueryResolutionType>((json: any) => {
  return typeof json === "string"
    ? decodeQueryResolutionString(json)
    : err<QueryResolutionType>(`Cannot decode query resolution from ${json}`);
});

export const nullableQueryResolutionDecoder = tOrNull(queryResolutionDecoder);

export function decodeProcessingStatusTypeString(
  resolutionString: string
): Result<ProcessingStatusType> {
  switch (resolutionString.toUpperCase()) {
    case "HOLDING":
      return ok(ProcessingStatusType.Holding);
    case "PENDING":
      return ok(ProcessingStatusType.Pending);
    case "SUBMITTED":
      return ok(ProcessingStatusType.Submitted);
    case "SUCCESS":
      return ok(ProcessingStatusType.Success);
    case "FAIL":
      return ok(ProcessingStatusType.Fail);
    default:
      return err<ProcessingStatusType>(
        `Cannot decode processing status type from ${resolutionString}`
      );
  }
}

export const processingStatusTypeDecoder = new JsonDecoder.Decoder<ProcessingStatusType>(
  (json: any) => {
    return typeof json === "string"
      ? decodeProcessingStatusTypeString(json)
      : err<ProcessingStatusType>(`Cannot decode processing type status from ${json}`);
  }
);

export function decodeImageStatusTypeString(resolutionString: string): Result<ImageStatusType> {
  switch (resolutionString.toUpperCase()) {
    case "PROCESSING":
      return ok(ImageStatusType.Processing);
    case "FAIL_PROCESSING":
      return ok(ImageStatusType.FailProcessing);
    case "DATA_INCOMPLETE":
      return ok(ImageStatusType.DataIncomplete);
    case "STUDY_ASSIGNMENT_PENDING":
      return ok(ImageStatusType.StudyAssignmentPending);
    case "DUPLICATE_IMAGE":
      return ok(ImageStatusType.DuplicateImage);
    case "CASE_ASSIGNMENT_PENDING":
      return ok(ImageStatusType.CaseAssignmentPending);
    case "LAB_QC_PENDING":
      return ok(ImageStatusType.LabQcPending);
    case "ISC_QC_PENDING":
      return ok(ImageStatusType.IscQcPending);
    case "READY_FOR_READING":
      return ok(ImageStatusType.ReadyForReading);
    case "COMPLETE":
      return ok(ImageStatusType.Complete);
    case "ARCHIVED":
      return ok(ImageStatusType.Archived);
    default:
      return err<ImageStatusType>(`Cannot decode image status type from ${resolutionString}`);
  }
}

export const imageStatusTypeDecoder = new JsonDecoder.Decoder<ImageStatusType>((json: any) => {
  return typeof json === "string"
    ? decodeImageStatusTypeString(json)
    : err<ImageStatusType>(`Cannot decode image status type from ${json}`);
});

export const processedImageDecoder = JsonDecoder.object<ProcessedImage>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    s3Key: JsonDecoder.string,
    uploadedAt: dateDecoder,
    studyId: tOrNull(JsonDecoder.string),
    uploaderId: JsonDecoder.string,
    cogKey: JsonDecoder.string,
    magnifications: JsonDecoder.array<number>(JsonDecoder.number, "magnifications"),
    extent: JsonDecoder.object<Polygon>(
      {
        type: JsonDecoder.isExactly("Polygon"),
        coordinates: JsonDecoder.array(JsonDecoder.array(latLngDecoder, "Points"), "Rings")
      },
      "Polygon"
    ),
    caseId: tOrNull(JsonDecoder.string),
    processingStatus: processingStatusTypeDecoder,
    status: imageStatusTypeDecoder,
    pixelSize: JsonDecoder.number,
    accessionNumber: tOrNull(JsonDecoder.string),
    biopsyLocation: tOrNull(JsonDecoder.string),
    physicalResolution: JsonDecoder.number,
    maxTmsZoom: JsonDecoder.number,
    zStackSize: tOrNull(JsonDecoder.number),
    lastImageComment: tOrNull(JsonDecoder.string),
    lastLabQueryId: tOrNull(JsonDecoder.string),
    ignored: JsonDecoder.boolean
  },
  "ProcessedImage"
);

export const unprocessedImageDecoder = JsonDecoder.object<UnprocessedImage>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    s3Key: JsonDecoder.string,
    uploadedAt: dateDecoder,
    studyId: tOrNull(JsonDecoder.string),
    uploaderId: JsonDecoder.string,
    caseId: tOrNull(JsonDecoder.string),
    processingStatus: tOrNull(processingStatusTypeDecoder),
    status: imageStatusTypeDecoder,
    accessionNumber: tOrNull(JsonDecoder.string),
    biopsyLocation: tOrNull(JsonDecoder.string),
    zStackSize: JsonDecoder.isNull(null),
    lastImageComment: tOrNull(JsonDecoder.string),
    lastLabQueryId: tOrNull(JsonDecoder.string),
    ignored: JsonDecoder.boolean
  },
  "UnprocessedImage"
);

export const queryStatsDecoder = JsonDecoder.object<QueryStats>(
  {
    numOpen: JsonDecoder.number,
    numAnswered: JsonDecoder.number,
    numClosed: JsonDecoder.number,
    numOverdue: JsonDecoder.number
  },
  "QueryStats"
);

export const imageDecoder = JsonDecoder.oneOf<ProcessedImage | UnprocessedImage>(
  [processedImageDecoder, unprocessedImageDecoder],
  "Image"
);

export function decodeCaseStatusString(caseStatusString: string): Result<CaseStatus> {
  switch (caseStatusString.toUpperCase()) {
    case "PENDING_LAB_QC":
      return ok(CaseStatus.PendingLabQC);
    case "PENDING_QC":
      return ok(CaseStatus.PendingQC);
    case "PROCESSED":
      return ok(CaseStatus.Processed);
    case "COMPLETED":
      return ok(CaseStatus.Completed);
    case "ON_HOLD":
      return ok(CaseStatus.OnHold);
    case "INVALID":
      return ok(CaseStatus.Invalid);
    case "ARCHIVED":
      return ok(CaseStatus.Archived);
    default:
      return err<CaseStatus>(`Cannot decode case status from ${caseStatusString}`);
  }
}

export const caseStatusDecoder = new JsonDecoder.Decoder<CaseStatus>((json: any) => {
  return typeof json === "string"
    ? decodeCaseStatusString(json)
    : err<CaseStatus>(`Cannot decode case status from ${json}`);
});

export const imageManagementRecordDecoder = JsonDecoder.object<ImageManagementRecord>(
  {
    id: tOrNull(JsonDecoder.string),
    name: tOrNull(JsonDecoder.string),
    s3Key: tOrNull(JsonDecoder.string),
    uploadedAt: tOrNull(dateDecoder),
    studyId: tOrNull(JsonDecoder.string),
    uploaderId: tOrNull(JsonDecoder.string),
    caseId: tOrNull(JsonDecoder.string),
    processingStatus: tOrNull(processingStatusTypeDecoder),
    status: tOrNull(imageStatusTypeDecoder),
    statusMessage: tOrNull(JsonDecoder.string),
    accessionNumber: tOrNull(JsonDecoder.string),
    biopsyLocation: tOrNull(JsonDecoder.string),
    zStackSize: tOrNull(JsonDecoder.number),
    lastImageComment: tOrNull(JsonDecoder.string),
    lastLabQueryId: tOrNull(JsonDecoder.string),
    ignored: tOrNull(JsonDecoder.boolean),

    labImageId: tOrNull(JsonDecoder.string),
    organizationId: tOrNull(JsonDecoder.string),
    machineReadableBarcode: tOrNull(JsonDecoder.string),
    appClientId: tOrNull(JsonDecoder.string),
    imageFilename: tOrNull(JsonDecoder.string),
    transactionId: tOrNull(JsonDecoder.string),
    labS3Key: tOrNull(JsonDecoder.string),
    labAccessionNumber: tOrNull(JsonDecoder.string),
    fcidAccessionNumber: tOrNull(JsonDecoder.string),
    stainType: tOrNull(JsonDecoder.string),
    sponsor: tOrNull(JsonDecoder.string),
    protocolId: tOrNull(JsonDecoder.string),
    siteId: tOrNull(JsonDecoder.string),
    subjectId: tOrNull(JsonDecoder.string),
    visitId: tOrNull(JsonDecoder.string),
    specimenCollectionDate: tOrNull(JsonDecoder.string),
    anatomicalSegment: tOrNull(JsonDecoder.string),
    qaComments: tOrNull(JsonDecoder.string),
    labImageCreatedAt: tOrNull(dateDecoder),
    updatedAt: tOrNull(dateDecoder),

    organizationName: tOrNull(JsonDecoder.string),
    studyName: tOrNull(JsonDecoder.string),
    isStudyMatched: JsonDecoder.boolean,
    isCaseMatched: JsonDecoder.boolean,
    histoProcedureId: tOrNull(JsonDecoder.string),
    endoProcedureId: tOrNull(JsonDecoder.string),
    inQuery: tOrNull(JsonDecoder.boolean)
  },
  "ImageManagementRecord"
);

export const imageManagementRecordArrayDecoder = JsonDecoder.array<ImageManagementRecord>(
  imageManagementRecordDecoder,
  "ImageManagementRecords"
);

export const studyAccessRecordViewDecoder = JsonDecoder.object<StudyAccessRecordView>(
  {
    studyId: JsonDecoder.string,
    userId: JsonDecoder.string,
    username: JsonDecoder.string,
    roleId: JsonDecoder.string,
    roleName: JsonDecoder.string
  },
  "StudyAccessRecordView"
);

export const studyAccessRecordArrayViewDecoder = JsonDecoder.array<StudyAccessRecordView>(
  studyAccessRecordViewDecoder,
  "StudyAccessRecordViews"
);

export const imageManagementCaseRecordDecoder = JsonDecoder.object<ImageManagementCaseRecord>(
  {
    caseId: tOrNull(JsonDecoder.string),
    procId: JsonDecoder.string,
    histoProcedureId: tOrNull(JsonDecoder.string),
    caseCreatedAt: dateDecoder,
    caseUpdatedAt: dateDecoder,
    studyId: tOrNull(JsonDecoder.string),
    subjectId: JsonDecoder.string,
    visitId: JsonDecoder.string,
    siteId: tOrNull(JsonDecoder.string),
    qc1By: tOrNull(JsonDecoder.string),
    qc2By: tOrNull(JsonDecoder.string),
    readBy: tOrNull(JsonDecoder.string),
    qc1CompletedAt: tOrNull(dateDecoder),
    qc2CompletedAt: tOrNull(dateDecoder),
    readAt: tOrNull(dateDecoder),
    caseStatusReverted: JsonDecoder.boolean,
    status: caseStatusDecoder,
    statusUpdatedAt: tOrNull(dateDecoder),
    statusUpdatedBy: tOrNull(JsonDecoder.string),
    openQueries: JsonDecoder.number,

    studyName: JsonDecoder.string,
    imagesExpected: JsonDecoder.number,
    totalImages: JsonDecoder.number,
    crAssignments: JsonDecoder.array<string>(JsonDecoder.string, "crAssignments"),

    labTech: studyAccessRecordArrayViewDecoder,
    iscQc: studyAccessRecordArrayViewDecoder
  },
  "ImageManagementCaseRecord"
);

export const imageManagementCaseRecordArrayDecoder = JsonDecoder.array<ImageManagementCaseRecord>(
  imageManagementCaseRecordDecoder,
  "ImageManagementCaseRecords"
);

export function decodeUnresolvedQueryType(queryType: string): Result<UnresolvedQueryType> {
  switch (queryType.toUpperCase()) {
    case "OPEN":
      return ok(QueryType.Open);
    case "PENDING_REVIEW":
      return ok(QueryType.PendingReview);
    default:
      return err<UnresolvedQueryType>(`Cannot decode query type from ${queryType}`);
  }
}

export const unresolvedQueryTypeDecoder = new JsonDecoder.Decoder<UnresolvedQueryType>(
  (json: any) => {
    return typeof json === "string"
      ? decodeUnresolvedQueryType(json)
      : err<UnresolvedQueryType>(`Cannot decode unresolved query type from ${json}`);
  }
);

export function decodeResolvedQueryType(queryType: string): Result<ResolvedQueryType> {
  switch (queryType.toUpperCase()) {
    case "RESOLVED":
      return ok(QueryType.Resolved);
    default:
      return err<ResolvedQueryType>(`Cannot decode query type from ${queryType}`);
  }
}

export const resolvedQueryTypeDecoder = new JsonDecoder.Decoder<ResolvedQueryType>((json: any) => {
  return typeof json === "string"
    ? decodeResolvedQueryType(json)
    : err<ResolvedQueryType>(`Cannot decode resolved query type from ${json}`);
});

export function decodeFixedQueryCategory(queryType: string): Result<FixedQueryCategory> {
  switch (queryType.toUpperCase()) {
    case "BLURRY":
      return ok(FixedQueryCategory.Blurry);
    case "INCOMPLETE":
      return ok(FixedQueryCategory.Incomplete);
    case "BAD_FILENAME":
      return ok(FixedQueryCategory.BadFilename);
    case "INTERNAL_QUERY_WITH_DM":
      return ok(FixedQueryCategory.InternalWithDM);
    case "INTERNAL_QUERY_WITH_PM":
      return ok(FixedQueryCategory.InternalWithPM);
    case "INTERNAL_QUERY_WITH_CR":
      return ok(FixedQueryCategory.InternalWithCR);
    default:
      return err<FixedQueryCategory>(`Cannot decode query type from ${queryType}`);
  }
}

export const queryCategoryDecoder = new JsonDecoder.Decoder<FixedQueryCategory>((json: any) => {
  return typeof json === "string"
    ? decodeFixedQueryCategory(json)
    : err<FixedQueryCategory>(`Cannot decode unresolved query type from ${json}`);
});

export const fixedQueryValueDecoder = JsonDecoder.object<FixedQueryValue>(
  {
    category: queryCategoryDecoder
  },
  "FixedQueryValue"
);

export const otherQueryValueDecoder = JsonDecoder.object<OtherQueryValue>(
  {
    text: JsonDecoder.string
  },
  "OtherQueryValue"
);

export const queryValueDecoder = JsonDecoder.oneOf<FixedQueryValue | OtherQueryValue>(
  [fixedQueryValueDecoder, otherQueryValueDecoder],
  "QueryDecoder"
);

export const unresolvedQueryDecoder = JsonDecoder.object<UnresolvedQuery>(
  {
    value: queryValueDecoder,
    queryType: unresolvedQueryTypeDecoder
  },
  "UnresolvedQuery"
);

export const resolvedQueryDecoder = JsonDecoder.object<ResolvedQuery>(
  {
    value: queryValueDecoder,
    queryType: resolvedQueryTypeDecoder,
    queryResolution: queryResolutionDecoder
  },
  "ResolvedQuery"
);

export const queryDecoder = JsonDecoder.oneOf<ResolvedQuery | UnresolvedQuery>(
  [resolvedQueryDecoder, unresolvedQueryDecoder],
  "QueryDecoder"
);

export function decodeQueryRecordCategory(queryType: string): Result<QueryRecordCategory> {
  switch (queryType.toUpperCase()) {
    case "BLURRY":
      return ok(QueryRecordCategory.Blurry);
    case "INCOMPLETE":
      return ok(QueryRecordCategory.Incomplete);
    case "BAD_FILENAME":
      return ok(QueryRecordCategory.BadFilename);
    case "OTHER":
      return ok(QueryRecordCategory.Other);
    case "INTERNAL_QUERY_WITH_DM":
      return ok(QueryRecordCategory.InternalWithDM);
    case "INTERNAL_QUERY_WITH_PM":
      return ok(QueryRecordCategory.InternalWithPM);
    case "INTERNAL_QUERY_WITH_CR":
      return ok(QueryRecordCategory.InternalWithCR);
    case "INTERNAL_QUERY_OTHER":
      return ok(QueryRecordCategory.InternalOther);
    default:
      return err<QueryRecordCategory>(`Cannot decode query type from ${queryType}`);
  }
}

export const queryRecordCategoryDecoder = new JsonDecoder.Decoder<QueryRecordCategory>(
  (json: any) => {
    return typeof json === "string"
      ? decodeQueryRecordCategory(json)
      : err<QueryRecordCategory>(`Cannot decode query record category type from ${json}`);
  }
);

export function decodeQueryObjectTypeString(
  queryObjectTypeString: string
): Result<QueryObjectType> {
  switch (queryObjectTypeString.toUpperCase()) {
    case "CASE":
      return ok(QueryObjectType.Case);
    case "IMAGE":
      return ok(QueryObjectType.Image);
    default:
      return err<QueryObjectType>(`Cannot decode query object type from ${queryObjectTypeString}`);
  }
}

export const queryObjectTypeDecoder = new JsonDecoder.Decoder<QueryObjectType>((json: any) => {
  return typeof json === "string"
    ? decodeQueryObjectTypeString(json)
    : err<QueryObjectType>(`Cannot decode case status from ${json}`);
});

export const queryViewRecordDecoder = JsonDecoder.object<QueryViewRecord>(
  {
    id: JsonDecoder.string,
    objectType: queryObjectTypeDecoder,
    objectId: JsonDecoder.string,
    studyId: tOrNull(JsonDecoder.string),
    caseId: tOrNull(JsonDecoder.string),
    organizationId: tOrNull(JsonDecoder.string),
    assessmentType: queryAssessmentTypeDecoder,
    queryStatus: decodeQueryStatusDecoder,
    dynamicStatus: tOrNull(decodeDynamicQueryStatusDecoder),
    resolution: tOrNull(queryResolutionDecoder),
    category: tOrNull(queryRecordCategoryDecoder),
    categoryOtherText: tOrNull(JsonDecoder.string),
    closeText: tOrNull(JsonDecoder.string),
    resolutionText: tOrNull(JsonDecoder.string),
    firstQueryReminderId: tOrNull(JsonDecoder.string),
    lastQueryReminderId: tOrNull(JsonDecoder.string),
    latestFollowUpOnDt: tOrNull(dateDecoder),
    openedAt: dateDecoder,
    openedByRoleId: tOrNull(JsonDecoder.string),
    closedAt: tOrNull(dateDecoder),
    closedBy: tOrNull(JsonDecoder.string),
    updatedAt: tOrNull(dateDecoder)
  },
  "QueryViewRecord"
);

export const queryDataDecoder = JsonDecoder.object<QueryData>(
  {
    id: JsonDecoder.string,
    objectType: queryObjectTypeDecoder,
    objectId: JsonDecoder.string,
    studyId: tOrNull(JsonDecoder.string),
    caseId: tOrNull(JsonDecoder.string),
    organizationId: tOrNull(JsonDecoder.string),
    assessmentType: queryAssessmentTypeDecoder,
    queryStatus: decodeQueryStatusDecoder,
    dynamicStatus: tOrNull(decodeDynamicQueryStatusDecoder),
    resolution: tOrNull(queryResolutionDecoder),
    category: tOrNull(queryRecordCategoryDecoder),
    categoryOtherText: tOrNull(JsonDecoder.string),
    closeText: tOrNull(JsonDecoder.string),
    resolutionText: tOrNull(JsonDecoder.string),
    firstQueryReminderId: tOrNull(JsonDecoder.string),
    lastQueryReminderId: tOrNull(JsonDecoder.string),
    latestFollowUpOnDt: tOrNull(dateDecoder),
    openedAt: dateDecoder,
    closedAt: tOrNull(dateDecoder),
    closedBy: tOrNull(JsonDecoder.string),
    updatedAt: tOrNull(dateDecoder)
  },
  "QueryData"
);

export const querySearchRecordDecoder = JsonDecoder.object<QuerySearchRecord>(
  {
    id: JsonDecoder.string,
    objectType: queryObjectTypeDecoder,
    objectId: JsonDecoder.string,
    studyId: tOrNull(JsonDecoder.string),
    caseId: tOrNull(JsonDecoder.string),
    organizationId: tOrNull(JsonDecoder.string),
    queryStatus: decodeQueryStatusDecoder,
    dynamicStatus: tOrNull(decodeDynamicQueryStatusDecoder),
    resolution: tOrNull(queryResolutionDecoder),
    category: tOrNull(queryRecordCategoryDecoder),
    categoryOtherText: tOrNull(JsonDecoder.string),
    closeText: tOrNull(JsonDecoder.string),
    resolutionText: tOrNull(JsonDecoder.string),
    firstQueryReminderId: tOrNull(JsonDecoder.string),
    lastQueryReminderId: tOrNull(JsonDecoder.string),
    latestFollowUpOnDt: tOrNull(dateDecoder),
    openedAt: dateDecoder,
    openedByUsername: JsonDecoder.string,
    openedByRoleId: tOrNull(JsonDecoder.string),
    closedAt: tOrNull(dateDecoder),
    closedBy: tOrNull(JsonDecoder.string),
    studyName: tOrNull(JsonDecoder.string),
    caseName: tOrNull(JsonDecoder.string),
    histoProcedureId: tOrNull(JsonDecoder.string),
    imageName: tOrNull(JsonDecoder.string),
    updatedAt: tOrNull(dateDecoder),
    queryWith: tOrNull(JsonDecoder.string),
    queryReasonText: tOrNull(JsonDecoder.string)
  },
  "QuerySearchRecord"
);

export const querySearchRecordDataDecoder = JsonDecoder.object<QuerySearchResultData>(
  {
    query: queryDataDecoder,
    dynamicStatus: tOrNull(decodeDynamicQueryStatusDecoder),
    studyName: tOrNull(JsonDecoder.string),
    caseName: tOrNull(JsonDecoder.string),
    histoProcedureId: tOrNull(JsonDecoder.string),
    imageName: tOrNull(JsonDecoder.string),
    openedByUsername: JsonDecoder.string,
    queryWith: tOrNull(JsonDecoder.string),
    queryReasonText: tOrNull(JsonDecoder.string)
  },
  "QuerySearchRecord"
);

export const queryRecordDecoder = JsonDecoder.oneOf<QueryRecord>(
  [queryViewRecordDecoder, querySearchRecordDecoder],
  "QueryRecord"
);

export const queriesDecoder = JsonDecoder.array(queryRecordDecoder, "QueryRecords");
export const querySearchDecoder = JsonDecoder.array(querySearchRecordDecoder, "QuerySearchRecords");

export const queryMetadataRoleDecoder = JsonDecoder.object<QueryMetadataRole>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    description: JsonDecoder.string,
    roleType: JsonDecoder.string,
    createdAt: dateDecoder,
    updatedAt: dateDecoder
  },
  "QueryMetadataRole"
);

export const queryMetadataRolesDecoder = JsonDecoder.array(
  queryMetadataRoleDecoder,
  "QueryMetadataRoles"
);

export const queryMetadataReasonDropdownValueDecoder = JsonDecoder.object<QueryMetadataReasonDropdownValue>(
  {
    id: JsonDecoder.string,
    parentId: JsonDecoder.string,
    reasonText: JsonDecoder.string
  },
  "QueryMetadataReasonDropdownValue"
);

export const queryMetadataReasonDropdownValuesDecoder = JsonDecoder.array(
  queryMetadataReasonDropdownValueDecoder,
  "QueryMetadataReasonDropdownValues"
);

export const queryMetadataReasonValueDecoder = JsonDecoder.object<QueryMetadataReasonValue>(
  {
    reasonOptionId: JsonDecoder.string,
    reasonOptionValueId: JsonDecoder.string,
    reasonText: JsonDecoder.string,
    children: tOrNull(queryMetadataReasonDropdownValuesDecoder)
  },
  "QueryMetadataReasonValue"
);

export const queryMetadataReasonValuesDecoder = JsonDecoder.array(
  queryMetadataReasonValueDecoder,
  "QueryMetadataReasonValues"
);

export const queryMetadataReasonDecoder = JsonDecoder.object<QueryMetadataReason>(
  {
    roleId: JsonDecoder.string,
    values: queryMetadataReasonValuesDecoder
  },
  "QueryMetadataReason"
);

export const queryMetadataReasonsDecoder = JsonDecoder.array(
  queryMetadataReasonDecoder,
  "QueryMetadataReasons"
);

export const queryMetadataDecoder = JsonDecoder.object<QueryMetadata>(
  {
    roles: rolesDecoder,
    caseReasons: queryMetadataReasonsDecoder,
    imageReasons: queryMetadataReasonsDecoder
  },
  "QueryMetadataResults"
);

export const queryDetailDecoder = JsonDecoder.object<QueryDetailRecord>(
  {
    id: JsonDecoder.string,
    objectType: queryObjectTypeDecoder,
    objectId: JsonDecoder.string,
    studyId: tOrNull(JsonDecoder.string),
    caseId: tOrNull(JsonDecoder.string),
    organizationId: tOrNull(JsonDecoder.string),
    assessmentType: queryAssessmentTypeDecoder,
    queryStatus: decodeQueryStatusDecoder,
    dynamicStatus: tOrNull(decodeDynamicQueryStatusDecoder),
    resolution: tOrNull(queryResolutionDecoder),
    category: queryRecordCategoryDecoder,
    categoryOtherText: tOrNull(JsonDecoder.string),
    closeText: tOrNull(JsonDecoder.string),
    resolutionText: tOrNull(JsonDecoder.string),
    firstQueryReminderId: tOrNull(JsonDecoder.string),
    lastQueryReminderId: tOrNull(JsonDecoder.string),
    latestFollowUpOnDt: tOrNull(dateDecoder),
    withRoleId: tOrNull(JsonDecoder.string),
    reasonOptionId: tOrNull(JsonDecoder.string),
    childReasonOptionId: tOrNull(JsonDecoder.string),
    openedAt: dateDecoder,
    openedBy: JsonDecoder.string,
    openedByRoleId: tOrNull(JsonDecoder.string),
    closedAt: tOrNull(dateDecoder),
    closedBy: tOrNull(JsonDecoder.string),
    updatedAt: dateDecoder,
    organizationName: tOrNull(JsonDecoder.string),
    createdBy: JsonDecoder.string,
    reasonText: tOrNull(JsonDecoder.string),
    childReasonText: tOrNull(JsonDecoder.string),
    resolutionOption: tOrNull(JsonDecoder.string),
    resolvedBy: tOrNull(JsonDecoder.string),
    resolvedAt: tOrNull(dateDecoder)
  },
  "QueryDetail"
);

export const queryReminderDecoder = JsonDecoder.object<QueryReminder>(
  {
    id: JsonDecoder.string,
    queryId: JsonDecoder.string,
    followUpOnDt: dateDecoder,
    remindedOnDt: tOrNull(dateDecoder),
    remindedBy: tOrNull(JsonDecoder.string),
    createdAt: dateDecoder,
    createdBy: JsonDecoder.string,
    updatedAt: dateDecoder
  },
  "QueryReminder"
);

export const queryRemindersDecoder = JsonDecoder.array(queryReminderDecoder, "QueryReminders");

export const queryDetailsCommentDecoder = JsonDecoder.object(
  {
    id: JsonDecoder.string,
    queryId: JsonDecoder.string,
    commentText: JsonDecoder.string,
    createdAt: dateDecoder,
    commentBy: JsonDecoder.string,
    username: JsonDecoder.string
  },
  "QueryDetailsComment"
);

export const queryDetailsCommentsDecoder = JsonDecoder.array(
  queryDetailsCommentDecoder,
  "QueryDetailsComments"
);

export const queryDetailsDecoder = JsonDecoder.object<QueryDetail>(
  {
    query: queryDetailDecoder,
    reminders: queryRemindersDecoder,
    comments: queryDetailsCommentsDecoder
  },
  "QueryDetails"
);

export const queryDetailsArrayDecoder = JsonDecoder.array(queryDetailsDecoder, "QueryDetails");

export const querySearchDataDecoder = JsonDecoder.array(
  querySearchRecordDataDecoder,
  "QuerySearchRecords"
);

export const imageAndQueryDecoder = JsonDecoder.object<ImageAndQuery>(
  {
    image: imageDecoder,
    query: tOrNull(queryDecoder),
    lastLabQuery: tOrNull(queryRecordDecoder),
    isShownToReader: JsonDecoder.boolean
  },
  "ImageWithQuery"
);

export const simpleCaseDecoder = JsonDecoder.object<SimpleCase>(
  {
    id: JsonDecoder.string,
    procId: JsonDecoder.string
  },
  "SimpleCase"
);

export const imageNoCaseDecoder = JsonDecoder.object<ImageNoCase>(
  {
    imageAndQuery: imageAndQueryDecoder
  },
  "ImageNoCase"
);

export const imageWithCaseDecoder = JsonDecoder.object<ImageWithCase>(
  {
    imageAndQuery: imageAndQueryDecoder,
    simpleCase: tOrNull(simpleCaseDecoder)
  },
  "ImageWithCase"
);

export const imageListViewDecoder = JsonDecoder.oneOf<ImageWithCase | ImageNoCase>(
  [imageWithCaseDecoder, imageNoCaseDecoder],
  "ImageListView"
);

export const imageListViewArrayDecoder = JsonDecoder.array<ImageListView>(
  imageListViewDecoder,
  "ImageListViews"
);

export const imageWithAnnotationsDecoder = JsonDecoder.object<ImageWithAnnotations>(
  {
    imageAndQuery: imageAndQueryDecoder,
    study: tOrNull(studyDecoder),
    annotations: JsonDecoder.array<Annotation>(annotationDecoder, "Annotations"),
    hpfAnnotationCount: JsonDecoder.number,
    hpfAnnotationClassesWithCount: hpfAnnotationClassWithCountArrayDecoder,
    pointAnnotationCount: JsonDecoder.number,
    pointAnnotationClassesWithCount: pointAnnotationClassWithCountArrayDecoder,
    freehandAnnotationCount: JsonDecoder.number,
    freehandAnnotationClassesWithCount: freehandAnnotationClassWithCountArrayDecoder,
    multilineAnnotationCount: JsonDecoder.number,
    multilineAnnotationClassesWithCount: multilineAnnotationClassWithCountArrayDecoder
  },
  "ImageWithAnnotations"
);

export const imageArrayDecoder = JsonDecoder.array<Image>(imageDecoder, "Images");

export const imageAndQueryArrayDecoder = JsonDecoder.array<ImageAndQuery>(
  imageAndQueryDecoder,
  "ImageAndQueries"
);

export const imageQueriesDecoder = JsonDecoder.array<ReadonlyArray<ImageAndQuery>>(
  imageAndQueryArrayDecoder,
  "ImageQueries"
);

export const readerCaseDecoder = JsonDecoder.object<ReaderCase>(
  {
    id: JsonDecoder.string,
    procId: JsonDecoder.string,
    histoProcedureId: tOrNull(JsonDecoder.string),
    createdAt: dateDecoder,
    studyId: JsonDecoder.string,
    status: caseStatusDecoder
  },
  "ReaderCase"
);

export const adminCaseDecoder = JsonDecoder.object<AdminCase>(
  {
    id: JsonDecoder.string,
    procId: JsonDecoder.string,
    histoProcedureId: tOrNull(JsonDecoder.string),
    createdAt: dateDecoder,
    studyId: JsonDecoder.string,
    subjectId: JsonDecoder.string,
    visitId: JsonDecoder.string,
    siteId: tOrNull(JsonDecoder.string),
    qc1By: tOrNull(JsonDecoder.string),
    qc2By: tOrNull(JsonDecoder.string),
    readBy: tOrNull(JsonDecoder.string),
    status: caseStatusDecoder,
    prevWorkflowStatuses: JsonDecoder.array<CaseStatus>(caseStatusDecoder, "PrevStatuses"),
    lastCaseComment: tOrNull(JsonDecoder.string),
    lastInternalQuery: tOrNull(queryRecordDecoder),
    openQueries: JsonDecoder.number
  },
  "AdminCase"
);

export const caseDecoder = JsonDecoder.oneOf<AdminCase | ReaderCase>(
  [adminCaseDecoder, readerCaseDecoder],
  "Case"
);

export const caseArrayDecoder = JsonDecoder.array<Case>(caseDecoder, "Cases");

export const readerCaseWithImagesDecoder = JsonDecoder.object<ReaderCaseWithImages>(
  {
    caseWithStatus: readerCaseDecoder,
    images: imageArrayDecoder
  },
  "ReaderCaseWithImages"
);

export const adminCaseWithImagesAndReadersDecoder = JsonDecoder.object<AdminCaseWithImagesAndReaders>(
  {
    caseWithStatus: adminCaseDecoder,
    images: imageArrayDecoder,
    imageQueries: imageQueriesDecoder,
    readers: readersStudyStatsDecoder
  },
  "AdminCaseWithImagesAndReaders"
);

export const caseWithImagesDecoder = JsonDecoder.oneOf<
  AdminCaseWithImagesAndReaders | ReaderCaseWithImages
>([adminCaseWithImagesAndReadersDecoder, readerCaseWithImagesDecoder], "CaseWithImages");

export const caseAndCountsNonAdminViewDecoder = JsonDecoder.object<CaseAndCountsNonAdminView>(
  {
    caseWithStatus: readerCaseDecoder,
    numberOfImages: JsonDecoder.number
  },
  "CaseAndCountsNonAdminView"
);

export const caseAndCountsAdminViewDecoder = JsonDecoder.object<CaseAndCountsAdminView>(
  {
    caseWithStatus: adminCaseDecoder,
    numberOfImages: JsonDecoder.number,
    numberOfQueries: JsonDecoder.number,
    qc1User: tOrNull(userDecoder),
    qc2User: tOrNull(userDecoder),
    assignedReaders: usersDecoder
  },
  "CaseAndCountsAdminView"
);

export const caseAndCountsDecoder = JsonDecoder.oneOf<
  CaseAndCountsAdminView | CaseAndCountsNonAdminView
>([caseAndCountsAdminViewDecoder, caseAndCountsNonAdminViewDecoder], "CaseAndCounts");

export const caseAndCountsArrayDecoder = JsonDecoder.array<
  CaseAndCountsAdminView | CaseAndCountsNonAdminView
>(caseAndCountsDecoder, "CaseAndCountsArray");

export const apiResponseDecoder = JsonDecoder.object<ApiResponse>(
  {
    success: JsonDecoder.boolean,
    message: JsonDecoder.string
  },
  "ApiResponse"
);

export const caseSummaryDecoder = JsonDecoder.object<CaseSummary>(
  {
    label: JsonDecoder.string,
    caseId: JsonDecoder.string,
    caseStatus: caseStatusDecoder,
    subjectId: JsonDecoder.string,
    visitId: JsonDecoder.string,
    siteId: tOrNull(JsonDecoder.string)
  },
  "Cases"
);

export const caseSummariesDecoder = JsonDecoder.array(caseSummaryDecoder, "Cases");
