/* eslint-disable functional/immutable-data */
import React, { useEffect } from "react";
import { Appearance, Box, Button, Callout, Icon, Intent, Text } from "@blasterjs/core";
import {
  ImageManagementCaseRecord,
  ImageManagementRecord,
  formatCaseStatus,
  CaseStatus,
  ImageStatusType
} from "../models";

import Page, { PageHeader, PageHeading, PageTabs } from "../components/Page";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableFilters } from "../components/Table";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  fetchImageManagementCasesTabCsv,
  fetchImageManagementImagesCaseResults,
  fetchImageManagementImagesTabCsv,
  fetchImageManagementImagesTabResults,
  openImageFilterDialog,
  openCaseFilterDialog,
  setCaseResultsFilters,
  setFilters
} from "../slices/imageManagement";
import { useNavigate, useParams } from "react-router";
import Timestamp from "../components/Timestamp";
import Avatar from "react-avatar";
import ImageManagementBulkCaseDialog from "../components/ImageManagementBulkCaseDialog";
import ImageManagementBulkImageDialog from "../components/ImageManagementBulkImageDialog";
import TooltipFlyout, { TooltipLink } from "../components/Tooltip";
import ImageManagementCaseDialogParent from "../components/ImageManagementCaseDialogParent";
import {
  CaseRowOptions,
  openImageManagementCaseDialogParent
} from "../slices/ImageManagementCaseDialogParent";
import ImageManagementImageDialogParent from "../components/ImageManagementImageDialogParent";
import ImageManagementEditTableCellDialog from "../components/ImageManagementEditTableCellDialog";
import {
  ImageRowOptions,
  openImageManagementImageDialogParent
} from "../slices/ImageManagementImageDialogParent";
import { formatDate } from "../utils";
import styled from "styled-components";
import { RolePermissions } from "../permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import {
  CaseTableColumns,
  ImageTableColumns,
  LabImageTableColumns,
  openImageManagementEditTableCellDialog
} from "../slices/ImageManagementEditTableCell";
import UploadSampleDataDialog from "../components/UploadSampleDataDialog";
import { openUploadSampleDataDialog } from "../slices/uploadSampleDataDialog";
import ImageSearchFilters from "../components/ImageManagementSearchFilters";
import CaseSearchFilters from "../components/ImageManagementCaseSearchFilters";

export const SubNavLink = styled(Button)`
  border: none;
  background: ${props => (props.active ? "#fff" : "transparent")};
  padding: 0 1rem;
  border-radius: 0;
  box-shadow: none;
  margin-right: 4px;
  border: 1px solid #dde0e6;
  border-width: ${props => (props.active && props.type === "tabs" ? "0 1px" : 0)};
  &:hover {
    background-color: ${props => (props.logo ? "transparent" : "rgba(179, 189, 204, 0.15)")};
  }
  &:after {
    position: absolute;
    top: ${props => (props.active && props.type === "tabs" ? 0 : "auto")};
    bottom: ${props => (props.active && props.type === "tabs" ? "auto" : 0)};
    left: 0;
    border-radius: 20px;
    right: 0;
    height: 2px;
    content: "";
    background-color: ${props => (props.active ? props.theme.colors.brand.color : "transparent")};
  }
`;

export function formatImageStatus(imageStatus: ImageStatusType | null): string | null {
  switch (imageStatus) {
    case ImageStatusType.Processing:
      return "Processing";
    case ImageStatusType.FailProcessing:
      return "Failed Processing";
    case ImageStatusType.DataIncomplete:
      return "Data Incomplete";
    case ImageStatusType.StudyAssignmentPending:
      return "Study Assignment Pending";
    case ImageStatusType.DuplicateImage:
      return "Duplicate Image";
    case ImageStatusType.CaseAssignmentPending:
      return "Case Assignment Pending";
    case ImageStatusType.LabQcPending:
      return "Lab QC Pending";
    case ImageStatusType.IscQcPending:
      return "ISC QC Pending";
    case ImageStatusType.ReadyForReading:
      return "Ready For Reading";
    case ImageStatusType.Complete:
      return "Complete";
    case ImageStatusType.Archived:
      return "Archived";
  }
  return null;
}

const ImageManagement = () => {
  const params = useParams();
  const tab: string = params.tab || "images";

  // const navigate = useNavigate();
  // const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const content = tab == "images" ? <ImageResults /> : <CaseResults />;

  // permissions check
  // if ("resource" in loggedInUser) {
  //   if (tab == "images") {
  //     navigate(`/accessDenied`);
  //   } else {
  //     navigate(`/accessDenied`);
  //   }
  // }

  return <>{content}</>;
};

export const WideTableContainer = styled.div`
  flex: 1;
  min-width: 190rem;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${props => props.theme.colors.gray200};
`;

const ImageResults = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const imageRecords = useAppSelector(state => state.imageManagement.imageRecords);

  const isSearchFilterOpen = useAppSelector(state => state.imageManagement.isImageFilterOpen);
  const searchFilters = useAppSelector(state => state.imageManagement.imageResultFilters);

  useEffect(() => {
    dispatch(fetchImageManagementImagesTabResults());
  }, [searchFilters]);

  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);
  //const [selectedRows, setSelectedRows] = useState<Array<ImageManagementRecord>>([]);

  const userCanSeeCases =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ViewPage]);
  const userCanSeeImages =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_ImageListTab_ViewImageslistTab]);

  const error =
    "errorMessage" in imageRecords ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{imageRecords.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const columns: Array<object> = [];

  if (
    "resource" in loggedInUser && // ViewAccessNumber external??
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewAccesionNumber])
  ) {
    columns.push({
      name: "Accession #",
      width: "20rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.accessionNumber || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.accessionNumber || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditAccessionNumber]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.AccessionNumber,
                      row: imageRecord,
                      columnValue: imageRecord.accessionNumber,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.accessionNumber}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_StudyData_ViewSponsor])
  ) {
    columns.push({
      name: "Sponsor",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.sponsor || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.sponsor || ""}>{imageRecord.sponsor}</div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_StudyData_ViewStudyName])
  ) {
    columns.push({
      name: "Study Name",
      width: "14rem",
      sortable: true,
      wrap: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.studyName || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.studyName || ""}>
          {"resource" in loggedInUser &&
            ((!imageRecord.studyName &&
              loggedInUser.resource.can([RolePermissions.AP_StudyData_EditStudyName])) ||
              (imageRecord.studyName &&
                loggedInUser.resource.can([
                  RolePermissions.AP_StudyData_EditStudyNamePopulated
                ]))) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.StudyName,
                      row: imageRecord,
                      columnValue: imageRecord.studyName,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.studyName}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewProtocolID])
  ) {
    columns.push({
      name: "Protocol ID",
      width: "14rem",
      wrap: true,
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.protocolId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.protocolId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditProtocolID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.ProtocolId,
                      row: imageRecord,
                      columnValue: imageRecord.protocolId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.protocolId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSiteID])
  ) {
    columns.push({
      name: "Site ID",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.siteId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.siteId || ""}>
          {!imageRecord.caseId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSiteID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.SiteID,
                      row: imageRecord,
                      columnValue: imageRecord.siteId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.siteId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSubjectID])
  ) {
    columns.push({
      name: "Subject ID",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.subjectId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.subjectId || ""}>
          {!imageRecord.caseId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSubjectID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.SubjectID,
                      row: imageRecord,
                      columnValue: imageRecord.subjectId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.subjectId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewVisitID])
  ) {
    columns.push({
      name: "Visit ID",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.visitId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.visitId || ""}>
          {!imageRecord.caseId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditVisitID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.VisitID,
                      row: imageRecord,
                      columnValue: imageRecord.visitId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.visitId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewHistopathologyProcedureID])
  ) {
    columns.push({
      name: "Histo Procedure ID",
      width: "25rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.histoProcedureId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.histoProcedureId || ""}>{imageRecord.histoProcedureId}</div>
      ),
      wrap: true
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewEndoProcedureID])
  ) {
    columns.push({
      name: "Endo Procedure ID",
      width: "25rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.endoProcedureId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.endoProcedureId || ""}>{imageRecord.endoProcedureId}</div>
      ),
      wrap: true
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewAnatomicalSegment])
  ) {
    columns.push({
      name: "Anatomical Segment",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.anatomicalSegment || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.anatomicalSegment || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditAnatomicalSegment]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.AnatomicalSegment,
                      row: imageRecord,
                      columnValue: imageRecord.anatomicalSegment,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.anatomicalSegment}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewCollectionDate])
  ) {
    columns.push({
      name: "Collection Date",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.specimenCollectionDate || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.specimenCollectionDate || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditCollectionDate]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.CollectionDate, // Not sure if correct
                      row: imageRecord,
                      columnValue: imageRecord.specimenCollectionDate,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.specimenCollectionDate}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewImageUploadDate])
  ) {
    columns.push({
      name: "Image Upload Date",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.labImageCreatedAt || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={formatDate(imageRecord.labImageCreatedAt)}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditImageUploadDate]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.ImageUploadDate,
                      row: imageRecord,
                      columnValue: formatDate(imageRecord.labImageCreatedAt),
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{formatDate(imageRecord.uploadedAt)}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewImageStatus])
  ) {
    columns.push({
      name: "Status",
      grow: 1.6,
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.status || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.status || ""}>{formatImageStatus(imageRecord.status)}</div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewInQuery])
  ) {
    columns.push({
      name: "In Query",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.inQuery || false
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewFilename])
  ) {
    columns.push({
      name: "File Name",
      sortable: true,
      width: "25rem",
      wrap: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.imageFilename || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.imageFilename || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditFileName]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: LabImageTableColumns.Filename,
                      row: imageRecord,
                      columnValue: imageRecord.imageFilename,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.imageFilename}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewQAComments])
  ) {
    columns.push({
      name: "Sample QA Comments",
      wrap: true,
      width: "20rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.qaComments || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div style={{ maxHeight: "5rem", overflow: "hidden" }} title={imageRecord.qaComments || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditQAComments]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: LabImageTableColumns.QaComments,
                      row: imageRecord,
                      columnValue: imageRecord.qaComments,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.qaComments}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewUploaderOrganization])
  ) {
    columns.push({
      name: "Organization",
      width: "16rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.organizationName || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.organizationName || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditUploaderOrganization]) && (
              <span
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.Organization,
                      row: imageRecord,
                      columnValue: imageRecord.organizationName,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.organizationName}
        </div>
      )
    });
  }

  // row options
  columns.push({
    right: true,
    grow: 0,
    cell: (imageRecord: ImageManagementRecord) => (
      <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
        <Icon name="menu" />

        <TooltipFlyout placement={"bottom"}>
          {imageRecord.studyId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_AssignImagetoCase]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.AssignToCase,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Assign to Case
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_CopyImage]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.CopyImage,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Copy Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.S_ImageListTab_MoveImage]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.MoveImage,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Move Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_DownloadImages]) && (
              <TooltipLink
                onClick={() => navigate(`/studies/${imageRecord.studyId}/images/${imageRecord.id}`)}
              >
                View Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_DownloadImages]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.DownloadImage,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Download Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_Archive]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.Archive,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Archive Image
              </TooltipLink>
            )}
        </TooltipFlyout>
      </Button>
    )
  });

  // const onSelectedRowChange = (selected: {
  //   allSelected: boolean;
  //   selectedCount: number;
  //   selectedRows: object[];
  // }) => {
  //   const rows = selected.selectedRows as Array<ImageManagementRecord>;
  //   setSelectedRows(rows);
  // };

  const viewImage = (record: ImageManagementRecord) => {
    navigate(`/studies/${record.studyId}/images/${record.id}`);
  };

  const uploadSampleDataButton = "resource" in loggedInUser &&
    loggedInUser.resource.can([
      RolePermissions.IM_ImagesListTab_UploadImagestoAssignedStudiesOnly
    ]) && (
      <>
        <Button
          style={{ marginRight: "12px" }}
          iconBefore="upload"
          onClick={() => dispatch(openUploadSampleDataDialog())}
          appearance="prominent"
          intent="primary"
        >
          Upload
        </Button>
        <UploadSampleDataDialog />
        &nbsp;
      </>
    );

  const exportReportImage = () => {
    dispatch(fetchImageManagementImagesTabCsv());
  };

  const exportReportButtonImage =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_ExportImageManagementTable]) ? (
      <Button
        iconBefore="download"
        appearance="prominent"
        onClick={exportReportImage}
        intent="primary"
      >
        Export Report
      </Button>
    ) : null;

  const content = error ? (
    error
  ) : (
    <WideTableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="studies-search"
            width="auto"
            defaultValue={""}
            placeholder={"Search"}
            onValueChange={name => dispatch(setFilters({ ...searchFilters, searchText: name }))}
          />
          <Box paddingLeft="30px" paddingTop="6px">
            <SubNavLink onClick={() => dispatch(openImageFilterDialog())}>
              <Icon name="plus" /> Add Filter
            </SubNavLink>
          </Box>
          <Box ml="auto">
            <span
              style={{ marginRight: "2rem", cursor: "pointer" }}
              onClick={() => window.location.reload()}
            >
              <Icon name="redo" />
            </span>
            {uploadSampleDataButton}
            {exportReportButtonImage}
          </Box>
        </Box>
      </TableFilters>
      {"resource" in imageRecords ? (
        <StyledDataTable
          columns={columns}
          data={imageRecords.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
          // selectableRows
          // onSelectedRowsChange={onSelectedRowChange}
          fixedHeader={true}
          onRowClicked={viewImage}
        />
      ) : null}
    </WideTableContainer>
  );
  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Image Management</PageHeading>
        </PageHeader>
        <PageTabs
          links={[
            ...(userCanSeeCases
              ? [
                  {
                    to: `/image-management/images`,
                    label: "Images"
                  }
                ]
              : []),
            ...(userCanSeeImages
              ? [
                  {
                    to: `/image-management/cases`,
                    label: "Cases"
                  }
                ]
              : [])
          ]}
        />
        <ImageSearchFilters isOpen={isSearchFilterOpen} filters={searchFilters} />
        <ImageManagementImageDialogParent />
        <ImageManagementBulkImageDialog />
        <ImageManagementEditTableCellDialog />
        {content}
        {/* {selectedRows && selectedRows.length > 0 && "resource" in loggedInUser && (
          <BulkOperationToolbar
            tab="image"
            loggedInUser={loggedInUser.resource}
            selectedCount={selectedRows.length}
          />
        )} */}
      </Box>
    </Page>
  );
};

export const BulkOperationTableContainer = styled.div`
  display: flex;
  max-width: 144rem;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.gray200};
`;
export const MenuOption = styled.div`
  margin-right: 15px;
`;

// Bulk Operations are disabled for now

// type BulkOperationToolbarProps = {
//   tab: string;
//   loggedInUser: LoggedInUser;
//   selectedCount: number;
// };

// const BulkOperationToolbar = (props: BulkOperationToolbarProps) => {
//   const dispatch = useAppDispatch();

//   if (props.tab == "case")
//     return (
//       <BulkOperationTableContainer>
//         <MenuOption>Selected {props.selectedCount}</MenuOption>
//         <MenuOption
//           onClick={() =>
//             dispatch(
//               openImageManagementCaseDialogParent({
//                 caseRowOption: CaseRowOptions.RevertStatus
//               })
//             )
//           }
//         >
//           Revert Status
//         </MenuOption>
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_CaseListTab_PutoHold]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementCaseDialogParent({
//                     caseRowOption: CaseRowOptions.PutOnHold
//                   })
//                 )
//               }
//             >
//               Put On Hold
//             </MenuOption>
//           )}
//         {props.selectedCount == 1 &&
//           "resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_CaseListTab_DownloadImages]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementCaseDialogParent({
//                     caseRowOption: CaseRowOptions.Download
//                   })
//                 )
//               }
//             >
//               Download Images
//             </MenuOption>
//           )}

//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_CaseListTab_ArchiveCase]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementCaseDialogParent({
//                     caseRowOption: CaseRowOptions.Archive
//                   })
//                 )
//               }
//             >
//               Archive
//             </MenuOption>
//           )}
//       </BulkOperationTableContainer>
//     );
//   else
//     return (
//       <BulkOperationTableContainer>
//         <MenuOption>Selected {props.selectedCount}</MenuOption>
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_AssignImagetoCase]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.AssignToCase,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Assign to Case
//             </MenuOption>
//           )}

//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_CopyImage]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.CopyImage,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Copy
//             </MenuOption>
//           )}
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.S_ImageListTab_MoveImage]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.MoveImage,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Move
//             </MenuOption>
//           )}

//         {props.selectedCount == 1 &&
//           "resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_DownloadImages]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.DownloadImage,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Download Image
//             </MenuOption>
//           )}
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_Archive]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.Archive,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Archive
//             </MenuOption>
//           )}
//       </BulkOperationTableContainer>
//     );
// };

// case tab
const CaseResults = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const caseRecords = useAppSelector(state => state.imageManagement.caseRecords);
  const isCaseFilterOpen = useAppSelector(state => state.imageManagement.isCaseFilterOpen);
  const searchFilters = useAppSelector(state => state.imageManagement.caseResultFilters);

  useEffect(() => {
    dispatch(fetchImageManagementImagesCaseResults());
  }, [searchFilters]);

  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);
  //const [selectedRows, setSelectedRows] = useState<Array<string>>([]);

  const error =
    "errorMessage" in caseRecords ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{caseRecords.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const columns: Array<object> = [];

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewHistopathologyProcedureID])
  ) {
    columns.push({
      name: "Histo Procedure ID",
      width: "25rem",
      wrap: true,
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.histoProcedureId || "",
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div>
          <div title={imageRecord.histoProcedureId || ""}>
            {"resource" in loggedInUser &&
              loggedInUser.resource.can([
                RolePermissions.AP_CaseData_EditHistopathologyProcedureID
              ]) && (
                <span
                  style={{ cursor: "pointer", color: "#9A9DA0" }}
                  onClick={() =>
                    dispatch(
                      openImageManagementEditTableCellDialog({
                        editingColumn: CaseTableColumns.HistoProcedureID,
                        row: imageRecord,
                        columnValue: imageRecord.histoProcedureId,
                        errorMessage: null
                      })
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPencil} />
                </span>
              )}
            &nbsp;{imageRecord.histoProcedureId}
          </div>
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewEndoProcedureID])
  ) {
    columns.push({
      name: "Endo Procedure ID",
      width: "25rem",
      wrap: true,
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.procId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.procId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditEndoProcedureID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.EndoProcedureID,
                      row: imageRecord,
                      columnValue: imageRecord.procId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.procId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_StudyData_ViewStudyName])
  ) {
    columns.push({
      name: "Study Name",
      sortable: true,
      wrap: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.studyName,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.studyName || ""}>{imageRecord.studyName}</div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSiteID])
  ) {
    columns.push({
      name: "Site ID",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.siteId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.siteId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSiteID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.SiteID,
                      row: imageRecord,
                      columnValue: imageRecord.siteId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.siteId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSubjectID])
  ) {
    columns.push({
      name: "Subject ID",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.subjectId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.subjectId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSubjectID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.SubjectID,
                      row: imageRecord,
                      columnValue: imageRecord.subjectId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.subjectId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewVisitID])
  ) {
    columns.push({
      name: "Visit ID",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.visitId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.visitId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditVisitID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.VisitID,
                      row: imageRecord,
                      columnValue: imageRecord.visitId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.visitId}
        </div>
      )
    });
  }

  columns.push({
    name: "Date Created",
    sortable: true,
    selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.caseCreatedAt,
    format: (imageRecord: ImageManagementCaseRecord) => (
      <Timestamp date={imageRecord.caseCreatedAt} />
    )
  });

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewExpectedNoofimages])
  ) {
    columns.push({
      name: "Images Expected",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.imagesExpected,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditExpectedNoofImages]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.ImagesExpected,
                      row: imageRecord,
                      columnValue: imageRecord.imagesExpected.toString(),
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.imagesExpected}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewNoofimagesReceived])
  ) {
    columns.push({
      name: "Images Received",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.totalImages,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditNoofImagesReceived]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.ImagesReceived,
                      row: imageRecord,
                      columnValue: imageRecord.totalImages.toString(),
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.totalImages}
        </div>
      )
    });
  }

  columns.push({
    name: "Status",
    sortable: true,
    selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.status,
    format: (imageRecord: ImageManagementCaseRecord) => (
      <div>{formatCaseStatus(imageRecord.status)}&nbsp;</div>
    )
  });

  columns.push({
    name: "Queries",
    selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.openQueries,
    sortable: true
  });
  columns.push({
    name: "Lab Tech QC",
    sortable: true,
    selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.labTech,
    format: (imageRecord: ImageManagementCaseRecord) => {
      return imageRecord.labTech.map(user => (
        <Avatar
          key={user.username}
          name={user.username}
          alt={user.username}
          size={"20"}
          round={true}
          textMarginRatio={0.1}
          textSizeRatio={30 / 14}
          style={{
            fontFamily: "InterVariable",
            zIndex: 100,
            position: "relative"
          }}
        />
      ));
    }
  });

  columns.push({
    name: "ISC QC",
    sortable: true,
    selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.iscQc,
    format: (imageRecord: ImageManagementCaseRecord) => {
      return imageRecord.iscQc.map(user => (
        <Avatar
          key={user.username}
          name={user.username}
          alt={user.username}
          size={"20"}
          round={true}
          textMarginRatio={0.1}
          textSizeRatio={30 / 14}
          style={{
            fontFamily: "InterVariable",
            zIndex: 100,
            position: "relative"
          }}
        />
      ));
    }
  });

  columns.push({
    name: "CR Assignment",
    sortable: true,
    selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.crAssignments,
    format: (imageRecord: ImageManagementCaseRecord) => {
      return imageRecord.crAssignments.map(username => (
        <Avatar
          key={username}
          name={username}
          alt={username}
          size={"20"}
          round={true}
          textMarginRatio={0.1}
          textSizeRatio={30 / 14}
          style={{
            fontFamily: "InterVariable",
            zIndex: 100,
            position: "relative"
          }}
        />
      ));
    }
  });
  columns.push({
    right: true,
    grow: 0,
    cell: (imageRecord: ImageManagementCaseRecord) => (
      <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
        <Icon name="menu" />

        <TooltipFlyout placement={"left-start"}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ChangeStatus]) &&
            imageRecord.status != CaseStatus.OnHold && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.RevertStatus,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Revert Status
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_PutoHold]) &&
            imageRecord.status == CaseStatus.OnHold && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.RemoveHold,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Remove Hold
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_PutoHold]) &&
            imageRecord.status != CaseStatus.OnHold && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.PutOnHold,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Put on Hold
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewimageViewer]) && (
              <TooltipLink
                onClick={() =>
                  navigate(`/studies/${imageRecord.studyId}/cases/${imageRecord.caseId}`)
                }
              >
                View Images
              </TooltipLink>
            )}

          {/* {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_DownloadImages]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.Download,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Download
              </TooltipLink>
            )} */}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ArchiveCase]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.Archive,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Archive
              </TooltipLink>
            )}
        </TooltipFlyout>
      </Button>
    )
  });

  // const onSelectedRowChange = (selected: {
  //   allSelected: boolean;
  //   selectedCount: number;
  //   selectedRows: object[];
  // }) => {
  //   const rows = selected.selectedRows as Array<ImageManagementCaseRecord>;
  //   const caseIds = rows.map(m => m.caseId || "");
  //   setSelectedRows(caseIds);
  // };

  const viewCase = (record: ImageManagementCaseRecord) => {
    navigate(`/studies/${record.studyId}/cases/${record.caseId}`);
  };

  const exportReportCase = () => {
    dispatch(fetchImageManagementCasesTabCsv());
  };

  const exportReportButtonCase =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ExportCaseLevelReport]) ? (
      <Button
        iconBefore="download"
        appearance="prominent"
        onClick={exportReportCase}
        intent="primary"
      >
        Export Report
      </Button>
    ) : null;

  const content = error ? (
    error
  ) : (
    <WideTableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="studies-search"
            width="auto"
            defaultValue={""}
            placeholder={"Search"}
            onValueChange={name =>
              dispatch(setCaseResultsFilters({ ...searchFilters, searchText: name }))
            }
          />
          <Box paddingLeft="30px" paddingTop="6px">
            <SubNavLink onClick={() => dispatch(openCaseFilterDialog())}>
              <Icon name="plus" /> Add Filter
            </SubNavLink>
          </Box>
          <Box ml="auto">
            <span
              style={{ marginRight: "2rem", cursor: "pointer" }}
              onClick={() => window.location.reload()}
            >
              <Icon name="redo" />
            </span>
            {exportReportButtonCase}
          </Box>
        </Box>
      </TableFilters>
      {"resource" in caseRecords && (
        <StyledDataTable
          columns={columns}
          data={caseRecords.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
          // selectableRows
          // onSelectedRowsChange={onSelectedRowChange}
          fixedHeader={true}
          onRowClicked={viewCase}
        />
      )}
    </WideTableContainer>
  );
  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Image Management</PageHeading>
        </PageHeader>
        <PageTabs
          links={[
            ...[
              {
                to: `/image-management/images`,
                label: "Images"
              }
            ],
            ...[
              {
                to: `/image-management/cases`,
                label: "Cases"
              }
            ]
          ]}
        />
        <CaseSearchFilters isOpen={isCaseFilterOpen} />
        <ImageManagementBulkCaseDialog />
        <ImageManagementCaseDialogParent />
        <ImageManagementEditTableCellDialog />
        {content}
        {/* {selectedRows && selectedRows.length > 0 && "resource" in loggedInUser && (
          <BulkOperationToolbar
            tab="case"
            loggedInUser={loggedInUser.resource}
            selectedCount={selectedRows.length}
          />
        )} */}
      </Box>
    </Page>
  );
};

export default ImageManagement;
